import {
  Component,
  OnInit,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import {
  PanAppState,
  PlatformService,
  SelectedCustomerState,
} from '@panamax/app-state';
import { InviteNewUserService } from '@shared/services/invite-new-user/invite-new-user.service';
import { User } from '@usf/user-types/user';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { InviteExistingUserModalComponent } from '../invite-existing-user-modal/invite-existing-user-modal.component';
import { InviteNewUserModalComponent } from '../invite-new-user-modal/invite-new-user-modal.component';

@Component({
  selector: 'app-invite-user-modal',
  templateUrl: './invite-user-modal.component.html',
  styleUrls: ['./invite-user-modal.component.scss'],
})
export class InviteUserModalComponent implements OnInit, OnDestroy {
  @ViewChild('inviteNewUserTemplate', { static: true })
  inviteNewUserTemplate: TemplateRef<any>;
  @ViewChild('inviteUserTypeSelectionTemplate', { static: true })
  inviteUserTypeSelectionTemplate: TemplateRef<any>;
  @ViewChild('inviteExistingUserTemplate', { static: true })
  inviteExistingUserTemplate: TemplateRef<any>;
  @ViewChild('existingUserModal')
  inviteExistingUserModal!: InviteExistingUserModalComponent;
  @ViewChild('newUserModal')
  inviteNewUserModal!: InviteNewUserModalComponent;

  inviteNewUserForm: FormGroup;
  inviteUserForm: FormGroup;

  inviteUserFlag$: Observable<boolean>;
  customer: SelectedCustomerState;

  modalTitle = 'i18n.profilePage.inviteUser.inviteAUser';

  mobileExistingUserInfoSelected = false;
  mobileExistingCustomersSelected = false;
  mobileNewUserInfoSelected = false;
  mobileNewCustomersSelected = false;
  mobileNewUserAdvOptionsOrganizationSelected = false;
  mobileNewUserAdvOptionsSiteRoleSelected = false;
  mobileNewUserAdvOptionsUserAccessSelected = false;
  mobileNewUserAdvOptionsNationalCustomizationSelected = false;
  inviteUserFlag = false;

  public destroy$ = new Subject<void>();

  customerInfo: Array<{ label: string; value: string | number }>;
  currentView:
    | 'inviteNewUser'
    | 'inviteUserTypeSelection'
    | 'inviteExistingUser' = 'inviteUserTypeSelection';
  userTypes = [
    {
      value: 'newUser',
      cy: 'radio-newUser',
      label: 'i18n.profilePage.inviteUser.newUser',
    },
    {
      value: 'existingUser',
      cy: 'radio-existingUser',
      label: 'i18n.profilePage.inviteUser.existingUser',
    },
  ];
  newUserResult: {
    newUserEmail: {
      currentValue: string;
      savedValue: string;
      hasChanges: boolean;
    };
    customers: {
      currentValue: { customerNumber: number; divisionNumber: number }[];
      savedValue: { customerNumber: number; divisionNumber: number }[];
      hasChanges: boolean;
    };
  } = {
    newUserEmail: { currentValue: '', savedValue: '', hasChanges: false },
    customers: { currentValue: [], savedValue: [], hasChanges: false },
  };
  existingUserResult: {
    existingUser: {
      currentValue: User;
      savedValue: User;
      isUserSelected: boolean;
    };
    customers: {
      currentValue: { customerNumber: number; divisionNumber: number }[];
      savedValue: { customerNumber: number; divisionNumber: number }[];
    };
  } = {
    existingUser: {
      currentValue: null,
      savedValue: null,
      isUserSelected: false,
    },
    customers: { currentValue: [], savedValue: [] },
  };
  newUserAdvOptions = {
    organization: {
      currentValue: '',
      savedValue: '',
      hasChanges: false,
    },
    siteRole: {
      currentValue: 'none',
      savedValue: 'none',
      hasChanges: false,
    },
    userAccessOptions: {
      currentValue: [],
      savedValue: [],
      hasChanges: false,
    },
    nationalCustomization: {
      currentValue: 'None',
      savedValue: 'None',
      hasChanges: false,
    },
  };

  constructor(
    public platformService: PlatformService,
    public inviteNewUserService: InviteNewUserService,
    private modalController: ModalController,
    private fb: FormBuilder,
    private panAppState: PanAppState,
  ) {}

  ngOnInit(): void {
    this.inviteUserFlag$ = this.inviteNewUserService.inviteUserFlag$();
    this.initForms();

    this.inviteUserFlag$
      .pipe(
        takeUntil(this.destroy$),
        tap(flag => (this.inviteUserFlag = flag)),
      )
      .subscribe();

    this.panAppState.customer$
      .pipe(
        takeUntil(this.destroy$),
        filter(cust => !!cust),
      )
      .subscribe(customer => {
        if (customer) {
          this.customer = customer;
          this.updateCustomerInfo(customer);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initForms() {
    this.inviteNewUserForm = this.fb.group({
      newUserEmailAddress: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(80)],
      ],
    });
    this.inviteUserForm = this.fb.group({
      inviteUserType: ['', Validators.required],
    });
  }

  public updateCustomerInfo(customer: SelectedCustomerState) {
    this.customerInfo = [
      {
        label: 'i18n.profilePage.inviteNewUser.modalCustomerName',
        value: customer.customerName,
      },
      {
        label: 'i18n.profilePage.inviteNewUser.modalCustomerNumber',
        value: customer.customerNumber,
      },
      {
        label: 'i18n.profilePage.inviteNewUser.modalDivisionNumber',
        value: customer.divisionNumber,
      },
    ];
  }

  selectExistingUser({
    user = null,
    customers = [],
    isUserSelected = false,
    resetCustomers = false,
    saveCustomers = false,
  }: {
    user?: User | null;
    customers?: { customerNumber: number; divisionNumber: number }[];
    isUserSelected?: boolean;
    resetCustomers?: boolean;
    saveCustomers?: boolean;
  }) {
    this.existingUserResult.existingUser.currentValue = user;
    this.existingUserResult.customers.currentValue = customers;
    this.existingUserResult.existingUser.isUserSelected = isUserSelected;
    if (resetCustomers) {
      this.existingUserResult.customers.savedValue = [];
    }
    if (saveCustomers) {
      this.existingUserResult.customers.savedValue = [...customers];
    }
  }

  selectNewUser({
    newUserEmail,
    customers,
  }: {
    newUserEmail: string;
    customers: [];
  }) {
    this.newUserResult.newUserEmail.currentValue = newUserEmail;
    this.newUserResult.newUserEmail.savedValue ||= newUserEmail;

    this.newUserResult.customers.currentValue = customers;
    if (!this.newUserResult.customers.savedValue.length) {
      this.newUserResult.customers.savedValue = [...customers];
    }

    this.newUserResult.newUserEmail.hasChanges =
      this.newUserResult.newUserEmail.currentValue !==
      this.newUserResult.newUserEmail.savedValue;
    this.newUserResult.customers.hasChanges =
      this.newUserResult.customers.currentValue !==
      this.newUserResult.customers.savedValue;

    this.inviteNewUserForm.get('newUserEmailAddress')?.setValue(newUserEmail);
  }

  selectNewUserAdvOptions({
    organization,
    siteRole,
    userAccessOptions,
    nationalCustomization,
  }: {
    organization: string;
    siteRole: string;
    userAccessOptions: string[];
    nationalCustomization: string;
  }) {
    this.newUserAdvOptions.organization.currentValue = organization;
    this.newUserAdvOptions.siteRole.currentValue = siteRole;
    this.newUserAdvOptions.userAccessOptions.currentValue = userAccessOptions;
    this.newUserAdvOptions.nationalCustomization.currentValue =
      nationalCustomization;

    this.newUserAdvOptions.organization.hasChanges =
      this.newUserAdvOptions.organization.currentValue !==
      this.newUserAdvOptions.organization.savedValue;
    this.newUserAdvOptions.siteRole.hasChanges =
      this.newUserAdvOptions.siteRole.currentValue !==
      this.newUserAdvOptions.siteRole.savedValue;
    this.newUserAdvOptions.userAccessOptions.hasChanges =
      JSON.stringify(this.newUserAdvOptions.userAccessOptions.currentValue) !==
      JSON.stringify(this.newUserAdvOptions.userAccessOptions.savedValue);
    this.newUserAdvOptions.nationalCustomization.hasChanges =
      this.newUserAdvOptions.nationalCustomization.currentValue !==
      this.newUserAdvOptions.nationalCustomization.savedValue;
  }

  resetMobileOptions(isNewUser: boolean) {
    if (isNewUser) {
      Object.keys(this.newUserAdvOptions).forEach(option => {
        this.newUserAdvOptions[option].currentValue =
          this.newUserAdvOptions[option].savedValue;
        this.newUserAdvOptions[option].hasChanges = false;
      });
      Object.keys(this.newUserResult).forEach(option => {
        this.newUserResult[option].currentValue =
          this.newUserResult[option].savedValue;
        this.newUserResult[option].hasChanges = false;
      });
      if (this.mobileNewUserInfoSelected) {
        this.inviteNewUserForm.reset();
        this.inviteNewUserModal.revertChanges(true);
      }

      this.mobileNewUserInfoSelected = false;
      this.mobileNewCustomersSelected = false;
      this.mobileNewUserAdvOptionsOrganizationSelected = false;
      this.mobileNewUserAdvOptionsSiteRoleSelected = false;
      this.mobileNewUserAdvOptionsUserAccessSelected = false;
      this.mobileNewUserAdvOptionsNationalCustomizationSelected = false;

      this.inviteNewUserModal?.clearSelectedOption();
      this.inviteNewUserModal?.revertChanges(false);
    } else {
      this.mobileExistingUserInfoSelected = false;
      this.mobileExistingCustomersSelected = false;
      this.inviteExistingUserModal?.revertChanges();
      this.inviteExistingUserModal?.clearSelectedOption();
    }
  }

  async dismiss(isMobileSection?: boolean, isNewUser?: boolean) {
    this.inviteUserForm?.reset();

    if (isMobileSection) {
      this.resetMobileOptions(isNewUser);
    } else {
      this.inviteNewUserForm.reset();
      await this.modalController.dismiss(true);
    }
  }

  onInviteNewUser() {
    if (!this.inviteUserFlag) {
      if (this.inviteNewUserForm?.valid && this.customer) {
        this.inviteNewUserService.inviteNewUser(
          this.inviteNewUserForm.get('newUserEmailAddress')?.value,
          [
            {
              customerNumber: this.customer?.customerNumber,
              divisionNumber: this.customer?.divisionNumber,
            },
          ],
        );
        this.dismiss();
      }
    } else {
      if (
        this.inviteNewUserForm?.valid &&
        this.newUserResult.customers.savedValue.length
      ) {
        this.inviteNewUserService.inviteNewUser(
          this.inviteNewUserForm.get('newUserEmailAddress')?.value,
          this.newUserResult.customers.savedValue,
        );
        this.dismiss();
      }
    }
  }

  onNewUserAdvOptionsSaveChanges() {
    Object.keys(this.newUserAdvOptions).forEach(option => {
      this.newUserAdvOptions[option].savedValue =
        this.newUserAdvOptions[option].currentValue;
      this.newUserAdvOptions[option].hasChanges = false;
    });
    this.inviteNewUserModal.updateOptions(this.newUserAdvOptions);
    this.dismiss(true, true);
  }

  onMobileExistingUserInformationNext() {
    this.mobileExistingUserInfoSelected = false;
    this.inviteExistingUserModal.mobileUserInfoSelectedNext();
    this.mobileExistingCustomersSelected = true;
    this.existingUserResult.existingUser.savedValue =
      this.existingUserResult.existingUser.currentValue;
    this.inviteExistingUserModal.updateResult(this.existingUserResult);
  }

  onMobileExistingCustomersnNext() {
    this.mobileExistingCustomersSelected = false;
    this.inviteExistingUserModal.clearSelectedOption();
    this.existingUserResult.customers.savedValue = [
      ...this.existingUserResult.customers.currentValue,
    ];
    this.inviteExistingUserModal.updateResult(this.existingUserResult);
  }

  onMobileNewUserInformationNext() {
    this.mobileNewUserInfoSelected = false;
    this.inviteNewUserModal.mobileUserInfoSelectedNext();
    this.mobileNewCustomersSelected = true;
    this.newUserResult.newUserEmail.savedValue =
      this.newUserResult.newUserEmail.currentValue;
    this.newUserResult.newUserEmail.currentValue = '';
    this.inviteNewUserModal.updateResult(this.newUserResult);
  }

  onMobileNewCustomersnNext() {
    this.mobileNewCustomersSelected = false;
    this.inviteNewUserModal.clearSelectedOption();
    this.newUserResult.customers.savedValue = [
      ...this.newUserResult.customers.currentValue,
    ];
    this.inviteNewUserModal.updateResult(this.newUserResult);
  }

  onmMbileNewUserAdvOptionsOrganizationnNext() {
    this.mobileNewUserAdvOptionsOrganizationSelected = false;
    this.inviteNewUserModal.clearSelectedOption();
  }

  onMoobileNewUserAdvOptionsSiteRoleNext() {
    this.mobileNewUserAdvOptionsSiteRoleSelected = false;
    this.inviteNewUserModal.clearSelectedOption();
  }

  onMoobileNewUserAdvOptionsUserAccessNext() {
    this.mobileNewUserAdvOptionsUserAccessSelected = false;
    this.inviteNewUserModal.clearSelectedOption();
  }

  onMobileNewUserAdvOptionsNationalCustomizationNext() {
    this.mobileNewUserAdvOptionsNationalCustomizationSelected = false;
    this.inviteNewUserModal.clearSelectedOption();
  }

  async onNext() {
    const selectedUserType = this.inviteUserForm.get('inviteUserType')?.value;
    if (selectedUserType === 'existingUser') {
      this.inviteNewUserService.trackInviteUserModalOpen(false);
      this.modalTitle = 'i18n.profilePage.inviteUser.inviteAnExistingUser';
      this.currentView = 'inviteExistingUser';
      await this.modalController.getTop()?.then(modal => {
        if (modal && this.platformService.platformType === 'desktop') {
          modal.cssClass = 'invite-existing-user-modal';
        }
      });
    } else {
      this.inviteNewUserService.trackInviteUserModalOpen(true);
      this.currentView = 'inviteNewUser';
      this.modalTitle = 'i18n.profilePage.inviteNewUser.button';
      await this.modalController.getTop()?.then(modal => {
        if (modal && this.platformService.platformType === 'desktop') {
          modal.cssClass = this.inviteUserFlag
            ? 'invite-existing-user-modal'
            : 'invite-new-user-modal';
        }
      });
    }
  }

  onSubmit(isNew: boolean) {
    if (isNew) {
      this.onInviteNewUser();
    } else {
      this.inviteNewUserService.inviteExistingUser(
        this.existingUserResult?.existingUser?.savedValue?.userId,
        this.existingUserResult?.existingUser?.savedValue?.userName,
        this.existingUserResult?.customers?.savedValue,
      );
      this.dismiss();
    }
  }

  isMobile(): boolean {
    return (
      this.platformService?.platformType ===
      this.platformService?.platformEnum?.mobile
    );
  }

  getHeaderClass(): string {
    return `ion-header-modal-${this.platformService.platformType}`;
  }

  getContentClass(): string {
    return `ion-content-modal-${this.platformService.platformType}`;
  }

  getFooterClass(): string {
    return `ion-footer-modal-${this.platformService.platformType}`;
  }

  getSubmitButtonClass(isNew: boolean): string {
    const isMobile = this.isMobile();

    if (isNew) {
      const newUserMail =
        this.inviteNewUserForm.get('newUserEmailAddress')?.value &&
        this.inviteNewUserForm.valid;
      const hasCustomers = isMobile
        ? this.newUserResult?.customers?.savedValue?.length > 0
        : this.newUserResult?.customers?.currentValue?.length > 0;

      return this.determineButtonClass(newUserMail && hasCustomers);
    } else {
      const existingUser = isMobile
        ? this.existingUserResult?.existingUser?.savedValue
        : this.existingUserResult?.existingUser?.currentValue?.userName == ''
          ? null
          : this.existingUserResult?.existingUser?.currentValue;
      const hasCustomers = isMobile
        ? this.existingUserResult?.customers?.savedValue?.length > 0
        : this.existingUserResult?.customers?.currentValue?.length > 0;

      return this.determineButtonClass(
        existingUser &&
          hasCustomers &&
          this.existingUserResult?.existingUser?.isUserSelected,
      );
    }
  }

  private determineButtonClass(isValid: boolean): string {
    return isValid
      ? this.isMobile()
        ? 'usf-fill-green-mobile-modal-button'
        : 'usf-fill-green-dsktp-tblt-modal-button'
      : this.isMobile()
        ? 'usf-fill-disabled-mobile-modal-button'
        : 'usf-fill-disabled-dsktp-tblt-modal-button';
  }

  get currentViewTemplate() {
    if (!this.inviteUserFlag) {
      return this.inviteNewUserTemplate;
    }
    switch (this.currentView) {
      case 'inviteNewUser':
        return this.inviteNewUserTemplate;
      case 'inviteUserTypeSelection':
        return this.inviteUserTypeSelectionTemplate;
      case 'inviteExistingUser':
        return this.inviteExistingUserTemplate;
      default:
        return this.inviteUserTypeSelectionTemplate;
    }
  }

  isInviteNewUserView(): boolean {
    return this.currentView === 'inviteNewUser';
  }

  isInviteUserTypeSelectionView(): boolean {
    return this.currentView === 'inviteUserTypeSelection';
  }
}
